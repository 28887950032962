import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../appContext";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import logo from "../assets/snagm-logo.png";
import Button from "react-bootstrap/button";
import "../css/header.css";
import { getAuth, signOut } from "firebase/auth";

export default function Header(props) {
  const myContext = useContext(AppContext);
  const navigate = useNavigate();
  const user = myContext.user;
  const { login } = props;

  const handleLogout = async () => {
    const auth = getAuth();
    await signOut(auth)
      .then(() => {
        navigate("/");
      })
      .catch((error) => {
        console.log("header: SIGN OUT ERROR:", error);
      });
  };

  const handleLogin = () => {
    navigate("/");
  };

  if (login) {
    return (
      <Row className="admin-header">
        <Col>
          <a href="/">
            <Image className="admin-logo" src={logo} />
          </a>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className="admin-header">
        <Col>
          <a href="/admin">
            <Image className="admin-logo" src={logo} />
          </a>
        </Col>
        <Col className="d-flex justify-content-end">
          {user ? (
            <Button
              variant="light"
              className="user-button"
              onClick={() => handleLogout()}
            >
              Sign Out
            </Button>
          ) : (
            <Button
              variant="light"
              className="user-button"
              onClick={() => handleLogin()}
            >
              Sign In
            </Button>
          )}
        </Col>
      </Row>
    );
  }
}
