import React from "react";
import { Routes, Route } from "react-router-dom";
import NoPage from "./noPage";
import DashHome from "./dashHome";
import DashWork from "./dashWork";
import PrintTent from "./printTent";
import PrintTable from "./printTable";
import PrintDrive from "./printDrive";
import PrintBOM from "./printBOM";

export default function AdminStack() {
  return (
    <Routes>
      <Route index element={<DashHome />} />
      <Route path="/work" element={<DashWork />} />
      <Route path="/print-tent" element={<PrintTent />} />
      <Route path="/print-table" element={<PrintTable />} />
      <Route path="/print-drive" element={<PrintDrive />} />
      <Route path="/print-bom" element={<PrintBOM />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  );
}
