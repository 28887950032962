import React from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { makeLink } from "../services/svcFunctions";

export default function CardAssets(props) {
  const { clientData } = props;
  let assetsList = [];
  if ("assets" in clientData) {
    assetsList = clientData.assets;
  }

  if (assetsList.length === 0) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Client Assets</h3>
        </Row>
        <Row className="v-top-10 big-bottom">
          <h6>No Assets Uploaded</h6>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Client Assets</h3>
      </Row>
      {assetsList.map((item, index) => (
        <Row key={index} className="v-top-10 big-bottom">
          <h6>{item.nickname}</h6>
          <Image
            src={makeLink(
              item.bucket === "scancodes" ? "scancodes" : "images",
              item.file
            )}
          />
        </Row>
      ))}
    </Col>
  );
}
