import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { getPendingRequests } from "../services/svcSignage";

export default function CardSignage() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [requestCount, setRequestCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = await getPendingRequests();
      setRequestCount(requestData.length);
      setLoading(false);
    };
    fetchData();
  }, []);

  const goPrintBOM = () => {
    navigate("/admin/print-bom");
  };

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Signage Requests</h3>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Signage Requests</h3>
      </Row>
      {requestCount === 0 ? (
        <Row className="campaign-row">
          <p>No pending requests</p>
        </Row>
      ) : (
        <Row className="campaign-row">
          <p>There are {requestCount} pending requests</p>
        </Row>
      )}
      <Row className="campaign-row">
        <Button
          disabled={requestCount === 0}
          onClick={() => goPrintBOM()}
          className="button-campaign"
        >
          Print Requests
        </Button>
      </Row>
    </Col>
  );
}
