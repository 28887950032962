import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { getWorkingOrder } from "../services/svcOrders";
import { getPhotoURL, uploadPhoto } from "../services/svcImages";
import { writeDocument, deleteDocument } from "../services/firestore";
import { services } from "../config/typeNames";

const niceDate = (date) => {
  const d = new Date(date);
  return d.toLocaleString();
};

export default function CardWork(props) {
  const navigate = useNavigate();
  const { orderID, clientData, updateClient } = props;
  const [order, setOrder] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [filesList, setFilesList] = useState([]);
  const [designerNotes, setDesignerNotes] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [uploadColor, setUploadColor] = useState("warning");
  const [processing, setProcessing] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [returnFile, setReturnFile] = useState("");
  const [ready, setReady] = useState(false);
  const [badFile, setBadFile] = useState(false);
  const [photoURL, setPhotoURL] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    const loadOrder = async () => {
      const orderRecord = await getWorkingOrder(orderID);
      const files = orderRecord.data.files;
      const urlList = [];
      for (let i = 0; i < files.length; i++) {
        const url = await getPhotoURL(files[i]);
        urlList.push(url);
      }
      setFilesList(urlList);
      if (orderRecord.data.designerFile) {
        const designURL = await getPhotoURL(orderRecord.data.designerFile);
        setPhotoURL(designURL);
      }
      setOrder(orderRecord);
      setLoaded(true);
    };
    loadOrder();
  }, [orderID, navigate]);

  const returnHours = (millis) => {
    const now = new Date().getTime();
    const diff = now - millis;
    const hours = diff / 1000 / 60 / 60;
    return hours.toFixed(1);
  };

  const countFiles = () => {
    const filesUploaded = order.data.files;
    let filesLength = 0;
    if (filesUploaded) {
      filesLength = filesUploaded.length;
    }
    return filesLength;
  };

  const handleChangeNotes = (event) => {
    setDesignerNotes(event.target.value);
    if (event.target.value.length > 0 && returnFile) {
      setReady(true);
    } else {
      setReady(false);
    }
  };

  const uploadFile = async (fileObj) => {
    try {
      //-- upload to storage
      const filename = await uploadPhoto(fileObj);
      setReturnFile(filename);
      const newURL = await getPhotoURL(filename);
      setPhotoURL(newURL);
      setUploading(false);
      setUploadColor("success");
      if (designerNotes.length > 0) {
        setReady(true);
      }
    } catch (err) {
      console.log("uploadFile: ERROR: " + err);
      setUploading(false);
      setUploadColor("danger");
    }
  };

  const handleFileChange = async (event) => {
    const fileObj = event.target.files && event.target.files[0];
    //-- check we have a good object
    if (!fileObj) {
      return;
    }
    //-- check the file type
    if (fileObj.type !== "image/jpeg") {
      setBadFile(true);
      return;
    }
    //-- check the file size
    if (fileObj.size > 1000000) {
      setBadFile(true);
      return;
    }
    //-- check the image dimensions
    const objectURL = URL.createObjectURL(fileObj);
    const img = document.createElement("img");
    img.onload = () => {
      if (img.width === 1080 && img.height === 1080) {
        setBadFile(false);
        setUploading(true);
        uploadFile(fileObj);
      } else {
        setBadFile(true);
      }
      URL.revokeObjectURL(img.src);
    };
    img.src = objectURL;
    event.target.value = null;
  };

  const handleSendWork = async () => {
    setProcessing(true);
    setUploading(true);
    let isGood = true;

    //-- update the order
    const newOrder = { ...order };
    const newNote = niceDate(Date.now()) + " DESIGNER: " + designerNotes;
    const updatedNotes = [...newOrder.data.orderNotes];
    updatedNotes.unshift(newNote);
    newOrder.data.orderNotes = updatedNotes;
    newOrder.data.designerFile = returnFile;
    newOrder.response = new Date().getTime();

    //-- move the order from WORKING to READY
    try {
      await writeDocument("orders-ready", orderID, newOrder);
    } catch (err) {
      console.log("handleSendWork: ERROR: " + err);
      isGood = false;
    }
    if (isGood) {
      //-- delete the record from ORDERS-WORKING
      await deleteDocument("orders-working", newOrder.data.id);

      //-- update the client record
      const updatedClient = { ...clientData };
      const clientOrders = [...updatedClient.orders];
      const index = clientOrders.findIndex(
        (order) => order.orderID === newOrder.data.id
      );
      const newOrderObject = { ...clientOrders[index] };
      newOrderObject.phase = "ready";
      clientOrders[index] = newOrderObject;
      updatedClient.orders = [...clientOrders];

      //-- write the client record
      try {
        await updateClient(updatedClient);
      } catch (err) {
        console.log("handleSendWork: ERROR: " + err);
      }
    }
    setSubmitted(true);
    setProcessing(false);
  };

  const goBack = () => {
    navigate("/");
  };

  const handleClick = () => {
    inputRef.current.click();
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>In Progress Order</h3>
      </Row>
      {loaded && (
        <React.Fragment>
          <Row>
            <a href={clientData.url} target="_blank" rel="noreferrer">
              <h4 className="snagm-blue">{order.data.clientName}</h4>
            </a>
          </Row>
          <Row>
            <p>
              <b>{services[order.data.couponType]} Coupon</b>
            </p>
          </Row>
          <Row>
            <p>Requested: {returnHours(order.submitted)} Hours Ago</p>
          </Row>
          <Row className="title-row">
            <p>Revisions Remaining: {order.data.revisions}</p>
          </Row>
          <Row className="notes-row">
            <p>{order.data.orderNotes[0]}</p>
          </Row>
          {filesList.length > 0 && (
            <Row className="v-top-10 m-bottom-10">
              <p className="text-center">
                {countFiles()} additional files uploaded by client:
              </p>
            </Row>
          )}
          {filesList.map((file, index) => (
            <Row key={index} className="text-center">
              <p>
                <a href={file} target="_blank" rel="noopener noreferrer">
                  Image {index + 1}
                </a>
              </p>
            </Row>
          ))}
          {photoURL && (
            <Row className="v-top-10">
              <Image src={photoURL} />
            </Row>
          )}
        </React.Fragment>
      )}
      {badFile && (
        <Row className="m-top-20 padded-row">
          <Alert variant="danger" className="text-center">
            image file must be .jpeg 1080x1080
          </Alert>
        </Row>
      )}
      <Row className="d-flex m-top-20 padded-row big-bottom">
        <input
          style={{ display: "none" }}
          ref={inputRef}
          type="file"
          onChange={handleFileChange}
        />
        <Button
          onClick={() => {
            handleClick();
          }}
          disabled={uploading}
          variant={uploadColor}
        >
          Upload Finished Work
        </Button>
      </Row>
      <Row className="m-all-0">
        <Form.Control
          as="textarea"
          placeholder="Notes to client..."
          rows={3}
          value={designerNotes}
          onChange={handleChangeNotes}
        />
      </Row>
      {!submitted ? (
        <Row className="v-top-10 padded-row">
          <Button
            onClick={() => {
              handleSendWork();
            }}
            disabled={!ready || processing}
            className="button-snagm"
          >
            Send To Client
          </Button>
        </Row>
      ) : (
        <React.Fragment>
          <Row className="padded-row">
            <Alert variant="success" className="m-top-20">
              Work Submitted to Client!
            </Alert>
          </Row>
          <Row className="padded-row">
            <Button onClick={() => goBack()}>OK</Button>
          </Row>
        </React.Fragment>
      )}
      <Row className="v-top-10">
        <p>Order Notes History:</p>
      </Row>
      {loaded && (
        <React.Fragment>
          {order.data.orderNotes.map((note, index) => (
            <Row key={index} className="v-top-5">
              <p>{note}</p>
            </Row>
          ))}
        </React.Fragment>
      )}
    </Col>
  );
}
