import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../appContext";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { getPendingOrder } from "../services/svcOrders";
import { getProfile } from "../services/svcProfile";
import { writeDocument, deleteDocument } from "../services/firestore";
import { services } from "../config/typeNames";
import {
  getClient,
  writeClient,
  getAdvertiser,
  writeAdvertiser,
} from "../services/svcClient";

export default function CardAccept(props) {
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const orderID = props.orderID;
  const [order, setOrder] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [notFound, setNotFound] = useState(false);

  useEffect(() => {
    if (!orderID) {
      navigate("/");
    }
    const loadOrder = async () => {
      const order = await getPendingOrder(orderID);
      setOrder(order);
      setLoaded(true);
    };
    loadOrder();
  }, [orderID, navigate]);

  const returnHours = (millis) => {
    const now = new Date().getTime();
    const diff = now - millis;
    const hours = diff / 1000 / 60 / 60;
    return hours.toFixed(1);
  };

  const countFiles = () => {
    const filesUploaded = order.data.files;
    let filesLength = 0;
    if (filesUploaded) {
      filesLength = filesUploaded.length;
    }
    return filesLength;
  };

  const goBack = () => {
    navigate("/");
  };

  const processAccept = async () => {
    console.log("START processAccept");
    setProcessing(true);
    //-- check if the order is still available in orders-pending
    const orderCheck = await getPendingOrder(orderID);
    if (!orderCheck) {
      setProcessing(false);
      setNotFound(true);
      setAccepted(true);
      return;
    }
    //-- start a transaction
    let isGood = true;
    //-- get the user profile
    const profile = await getProfile(user.uid);
    const userName = profile.data.name;
    //-- update the order record with ACCEPTED-BY information
    const newOrder = { ...order };
    newOrder.designer = profile.data.designer;
    newOrder.uid = user.uid;
    newOrder.data.acceptedBy = userName;
    newOrder.data.accepted = new Date().getTime();
    //-- write a new record to ORDERS-WORKING
    try {
      await writeDocument("orders-working", newOrder.data.id, newOrder);
    } catch (error) {
      isGood = false;
    }
    if (isGood) {
      //-- delete the record from ORDERS-PENDING
      await deleteDocument("orders-pending", newOrder.data.id);
    }

    //-- update the client record
    let updatedClient = {};
    console.log("order.data.couponType: " + order.data.couponType);
    if (order.data.couponType === "paid") {
      updatedClient = await getAdvertiser(newOrder.clientID);
    } else {
      updatedClient = await getClient(newOrder.clientID);
    }
    if (!updatedClient) {
      console.log("ERROR: updatedClient not found");
      setProcessing(false);
      setNotFound(true);
      setAccepted(true);
      return;
    }
    if (!updatedClient.orders) {
      console.log("ERROR: updatedClient.orders not found");
      setProcessing(false);
      setNotFound(true);
      setAccepted(true);
      return;
    }
    const clientOrders = [...updatedClient.orders];
    const index = clientOrders.findIndex(
      (order) => order.orderID === newOrder.data.id
    );
    const newOrderObject = { ...clientOrders[index] };
    newOrderObject.phase = "working";
    clientOrders[index] = newOrderObject;
    updatedClient.orders = [...clientOrders];

    //-- write the client record
    try {
      if (order.data.couponType === "paid") {
        await writeAdvertiser(updatedClient);
      } else {
        await writeClient(updatedClient);
      }
    } catch (err) {
      console.log("writeClient: ERROR: " + err);
    }
    setProcessing(false);
    setAccepted(true);
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Pending Order</h3>
      </Row>
      {loaded && (
        <React.Fragment>
          <Row>
            <h4 className="snagm-blue">{order.data.clientName}</h4>
          </Row>
          <Row>
            <p>
              <b>{services[order.data.couponType]} Coupon</b>
            </p>
          </Row>
          <Row className="title-row">
            <p>Requested: {returnHours(order.submitted)} Hours Ago</p>
          </Row>
          <Row className="p-all-10 h-margin-reset sublighted">
            <p>{order.data.orderNotes[0]}</p>
          </Row>
          <Row className="v-top-10">
            <p className="text-center">
              {countFiles()} Photo Files Uploaded By Client
            </p>
          </Row>
          {accepted ? (
            <React.Fragment>
              <Row className="campaign-row">
                {notFound ? (
                  <Alert variant="danger" className="m-top-20 text-center">
                    Sorry, order not found.
                  </Alert>
                ) : (
                  <Alert variant="success" className="m-top-20 text-center">
                    Order Accepted! Check your <b>In Progress</b> list.
                  </Alert>
                )}
              </Row>
              <Row className="campaign-row">
                <Button onClick={() => goBack()} className="button-snagm">
                  OK
                </Button>
              </Row>
            </React.Fragment>
          ) : (
            <Row className="campaign-row v-top-10">
              <Button
                onClick={() => processAccept()}
                disabled={processing}
                className="button-campaign"
              >
                Accept Order
              </Button>
            </Row>
          )}
        </React.Fragment>
      )}
    </Col>
  );
}
