import { queryCollection, readDocument, writeDocument } from "./firestore";

export const getPendingRequests = async () => {
  const orders = await queryCollection("signage", "status", "pending");
  if (orders) {
    return orders;
  }
  return [];
};

export const setRequestsPrinted = async (list) => {
  Promise.all(
    list.map(async (id) => {
      const data = await readDocument("signage", id);
      if (data) {
        data.status = "printed";
        data.printed = new Date().toISOString();
        await writeDocument("signage", id, data);
      }
    })
  );
  return null;
};
