import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../css/printDrive.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { makeLink } from "../services/svcFunctions";

const top1 = "ENJOY OUR";
const top2 = "EXCLUSIVE";
const top3 = "COUPONS";
const bottom = "SCAN HERE";

export default function PrintDrive() {
  const [searchParams] = useSearchParams();
  const imageFile = searchParams.get("img");
  const [loading, setLoading] = useState(true);
  const [imageURL, setImageURL] = useState("");

  useEffect(() => {
    const loadClient = async () => {
      const fileName = imageFile + ".png";
      const link = makeLink("scancodes", fileName);
      setImageURL(link);
      setLoading(false);
    };
    loadClient();
  }, [imageFile]);

  if (loading) {
    return <Container fluid></Container>;
  }

  return (
    <Container className="print-drive-container" fluid>
      <Row className="print-drive-row">
        <Col className="print-drive-top-block drive-left">
          <p className="print-drive-very-top">{top1}</p>
          <p className="print-drive-top">{top2}</p>
          <p className="print-drive-title-top">{top3}</p>
          <Image className="print-drive-image" src={imageURL} />
          <p className="print-drive-bottom">{bottom}</p>
        </Col>
        <Col className="print-drive-top-block drive-right">
          <p className="print-drive-very-top">{top1}</p>
          <p className="print-drive-top">{top2}</p>
          <p className="print-drive-title-top">{top3}</p>
          <Image className="print-drive-image" src={imageURL} />
          <p className="print-drive-bottom">{bottom}</p>
        </Col>
      </Row>
      <Row className="print-drive-row">
        <Col className="print-drive-bottom-block drive-left">
          <p className="print-drive-very-top">{top1}</p>
          <p className="print-drive-top">{top2}</p>
          <p className="print-drive-title-top">{top3}</p>
          <Image className="print-drive-image" src={imageURL} />
          <p className="print-drive-bottom">{bottom}</p>
        </Col>
        <Col className="print-drive-bottom-block drive-right">
          <p className="print-drive-very-top">{top1}</p>
          <p className="print-drive-top">{top2}</p>
          <p className="print-drive-title-top">{top3}</p>
          <Image className="print-drive-image" src={imageURL} />
          <p className="print-drive-bottom">{bottom}</p>
        </Col>
      </Row>
    </Container>
  );
}
