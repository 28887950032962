import { getApp } from "firebase/app";
import {
  getFirestore,
  doc,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  updateDoc,
  query,
  collection,
  where,
} from "firebase/firestore";

export async function writeDocument(name, key, data) {
  try {
    const app = getApp();
    if (app) {
      const db = getFirestore(app);
      await setDoc(doc(db, name, key), data);
      return data;
    }
  } catch (error) {
    console.log("writeDocument: ERROR:", error);
  }
  return null;
}

export async function readDocument(name, key) {
  //console.log("readDocument: name:", name);
  //console.log("readDocument: key:", key);
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.log("readDocument: NO SUCH DOCUMENT:", key);
      return null;
    }
  }
}

export async function updateDocument(name, key, data) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await setDoc(docRef, data, { merge: true });
    return data;
  }
  return null;
}

export async function mergeDocument(name, key, data) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await updateDoc(docRef, data, { merge: true });
    return data;
  }
  return null;
}

export async function deleteDocument(name, key) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const docRef = doc(db, name, key);
    await deleteDoc(docRef);
  }
  return null;
}

export async function queryCollection(collectionName, attribName, attribValue) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const q = query(
      collection(db, collectionName),
      where(attribName, "==", attribValue)
    );
    const querySnapshot = await getDocs(q);
    const results = [];
    querySnapshot.forEach((doc) => {
      //-- insert the document ID into the data
      const data = doc.data();
      data.id = doc.id;
      results.push(data);
    });
    return results;
  }
  return [];
}

export async function getCollection(collectionName) {
  const app = getApp();
  if (app) {
    const db = getFirestore(app);
    const snapshot = await getDocs(collection(db, collectionName));
    const results = [];
    snapshot.forEach((doc) => {
      results.push(doc.data());
    });
    return results;
  }
  return null;
}
