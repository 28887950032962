import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../css/printTent.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { getClient } from "../services/svcClient";
import { makeLink } from "../services/svcFunctions";

export default function PrintTent() {
  const [searchParams] = useSearchParams();
  //const scanCode = "SNAGM DIGITAL COUPONS";
  const code = searchParams.get("sc");
  const scanCode = code ? "SUBSCRIBE CODE: " + code : "SNAGM DIGITAL COUPONS";
  const clientID = searchParams.get("id");
  const imageFile = searchParams.get("img");
  const [loading, setLoading] = useState(true);
  const [clientName, setClientName] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [clientFont, setClientFont] = useState({ fontSize: "8px" });

  useEffect(() => {
    const loadClient = async () => {
      if (!clientID) return;
      const client = await getClient(clientID);
      const name = client.clientName;
      setClientName(name);
      if (name.length > 25) {
        setClientFont({ fontSize: "6px" });
      }
      const fileName = imageFile + ".png";
      const link = makeLink("scancodes", fileName);
      setImageURL(link);
      setLoading(false);
    };
    if (clientID === "demo") {
      setClientName("YOUR RESTAURANT NAME");
      const link = makeLink("scancodes", "demo-qr-code.png");
      setImageURL(link);
      setLoading(false);
    } else {
      loadClient();
    }
  }, [clientID, imageFile]);

  if (loading) {
    return <Container fluid></Container>;
  }

  return (
    <Container className="print-tent-container" fluid>
      <Row className="print-tent-row">
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
      </Row>
      <Row className="print-tent-row">
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
      </Row>
      <Row className="print-tent-row">
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
      </Row>
      <Row className="print-tent-row">
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
      </Row>
      <Row className="print-tent-row">
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
        <Col className="print-tent-block">
          <p style={clientFont} className="print-tent-top">
            {clientName}
          </p>
          <Image className="print-tent-image" src={imageURL} />
          <p className="print-tent-bottom">{scanCode}</p>
        </Col>
      </Row>
    </Container>
  );
}
