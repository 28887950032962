export function makeISO(d) {
  const yyyy = d.getFullYear().toString();
  const m = d.getMonth() + 1;
  let mm = m.toString();
  let dd = d.getDate().toString();
  if (mm.length < 2) {
    mm = "0" + mm;
  }
  if (dd.length < 2) {
    dd = "0" + dd;
  }
  const isoDate = yyyy + mm + dd;
  return isoDate;
}

export function isoToday() {
  const dateToday = new Date();
  return makeISO(dateToday);
}

export function niceDate(iso) {
  if (iso === undefined) return "";
  const yyyy = iso.substring(0, 4);
  const mm = iso.substring(4, 6);
  const dd = iso.substring(6, 8);
  const niceDate = mm + "/" + dd + "/" + yyyy;
  return niceDate;
}

export function writeLocal(key, data) {
  const strData = JSON.stringify(data);
  localStorage.setItem(key, strData);
}

export function readLocal(key) {
  const strData = localStorage.getItem(key);
  const data = JSON.parse(strData);
  return data;
}

export function genID(length) {
  var chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var charLength = chars.length;
  var result = "";
  for (var i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * charLength));
  }
  return result;
}

export function createImageName(clientID) {
  const randomPart = genID(10);
  const filename = clientID + "-" + randomPart + ".jpg";
  return filename;
}

export function makeLink(folder, filename) {
  const mainLink = "https://storage.googleapis.com/snagm-b6068.appspot.com/";
  const fullLink = mainLink + folder + "/" + filename;
  return fullLink;
}
