import React, { useEffect, useMemo, useState } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function CardAccount(props) {
  const profile = useMemo(
    () => (props.designer ? props.designer : {}),
    [props.designer]
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (profile) {
      setLoading(false);
    }
  }, [profile]);

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Account</h3>
        </Row>
        <Row>
          <p>Welcome to SNAGM!</p>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Designer Stats</h3>
        <h5 className="snagm-blue">{profile.displayName}</h5>
      </Row>
      <Row>
        <p>
          90 Day Designs Completed: <b>{profile.completedOrders.length}</b>
        </p>
        <p>
          90 Day Average Completion Time (Hours): <b>{profile.avgHours}</b>
        </p>
        <p>
          90 Day Average Client Satisfaction Rating: <b>{profile.avgRating}</b>
        </p>
        <Row className="v-top-20 padded-row-lg">
          <hr />
        </Row>
      </Row>
      <Row className="m-bottom-10">
        <p>Last 18 Months Earnings:</p>
      </Row>
      {profile.pastMonthPayouts.length > 0 ? (
        <React.Fragment>
          {profile.pastMonthPayouts.map((payout, index) => {
            return (
              <Row key={index}>
                <p>
                  {payout.payDate}: {payout.payAmount}
                </p>
              </Row>
            );
          })}
        </React.Fragment>
      ) : (
        <Row>
          <p>No earnings yet.</p>
        </Row>
      )}
    </Col>
  );
}
