import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import { AppContext } from "../appContext";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardInbox from "./cardInbox";
import CardProgress from "./cardProgress";
import CardCompleted from "./cardCompleted";
import CardAccount from "./cardAccount";
import CardPrinting from "./cardPrinting";
import CardSignage from "./cardSignage";
import { getProfile, getDesigner } from "../services/svcProfile";
import { getAuth, signOut } from "firebase/auth";

export default function DashHome() {
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  const user = myContext.user;
  const [isReady, setIsReady] = useState(false);
  const [designer, setDesigner] = useState({});

  useEffect(() => {
    const goLogout = async () => {
      const auth = getAuth();
      await signOut(auth);
    };

    const loadProfile = async () => {
      const data = await getProfile(user.uid);
      const profileData = data.data;
      if (!("designer" in profileData)) {
        goLogout();
      } else {
        const designerID = profileData.designer;
        const designerData = await getDesigner(designerID);
        setDesigner(designerData);
      }
      setIsReady(true);
    };

    loadProfile();
  }, [user.uid, navigate]);

  return (
    <Container fluid>
      <Header />
      {isReady && (
        <Row className="grid-container main-row">
          <Col>
            <CardInbox />
          </Col>
          <Col>
            <CardProgress />
          </Col>
          <Col>
            <CardAccount designer={designer} />
          </Col>
          <Col>
            <CardCompleted designer={designer} />
          </Col>
          <Col>
            <CardSignage designer={designer} />
          </Col>
          <Col>
            <CardPrinting designer={designer} />
          </Col>
        </Row>
      )}
    </Container>
  );
}
