import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { queryCollection } from "../services/firestore";

const demoData = {
  clientID: "demo",
  imageFile: "demo-qr-code.png",
};

export default function CardPrinting() {
  const navigate = useNavigate();
  const [scancode, setScancode] = useState("");

  const changeScancode = (e) => {
    if (e.target.value.length > 5) return;
    const newCode = e.target.value.toUpperCase();
    setScancode(newCode);
  };

  const getScanData = async () => {
    if (scancode === "DEMO") return demoData;
    const results = await queryCollection("scancodes", "scanCode", scancode);
    if (results.length === 0) return null;
    return results[0];
  };

  const goPrintScreen = async (pathCode) => {
    const goodScan = await getScanData();
    if (!goodScan) {
      alert("Invalid Scancode");
      return;
    }
    let imageFile = goodScan.imageFile;
    //--chop the last 4 characters off the imageFile name
    imageFile = imageFile.substring(0, imageFile.length - 4);
    const clientID = goodScan.clientID;
    navigate({
      pathname: pathCode,
      search: createSearchParams({
        id: clientID,
        sc: scancode,
        img: imageFile,
      }).toString(),
    });
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Sticker Printing</h3>
      </Row>
      <Row className="big-bottom">
        <Col className="scancode-print">
          <p>Scancode: </p>
        </Col>
        <Col>
          <Form.Control
            type="text"
            value={scancode}
            onChange={(e) => changeScancode(e)}
          />
        </Col>
        <Col></Col>
      </Row>
      <Row className="padded-row">
        <Col className="scancode-column-center">
          <Button
            onClick={() => goPrintScreen("print-tent")}
            className="snagm-button"
            variant="primary"
          >
            Print for TENT
          </Button>
        </Col>
        <Col className="scancode-column-center">
          <Button
            onClick={() => goPrintScreen("print-table")}
            className="snagm-button"
            variant="primary"
          >
            Print for TABLE
          </Button>
        </Col>
      </Row>
      <Row className="drive-print-row v-top-20">
        <Button
          onClick={() => goPrintScreen("print-drive")}
          className="snagm-button"
          variant="primary"
        >
          Print for DRIVE THRU
        </Button>
      </Row>
    </Col>
  );
}
