import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../css/dashboard.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Header from "./header";
import CardAccept from "./cardAccept";
import CardLibrary from "./cardLibrary";
import CardWork from "./cardWork";
import CardAssets from "./cardAssets";
import {
  getClient,
  writeClient,
  getAdvertiser,
  writeAdvertiser,
} from "../services/svcClient";

export default function DashWork() {
  const [searchParams] = useSearchParams();
  const mode = searchParams.get("m");
  const orderID = searchParams.get("id");
  const clientID = searchParams.get("cid");
  const couponType = searchParams.get("t");
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState({});

  const updateClient = async (clientData) => {
    if (couponType === "paid") {
      await writeAdvertiser(clientData);
    } else {
      await writeClient(clientData);
    }
    setClientData(clientData);
  };

  useEffect(() => {
    const loadClient = async () => {
      let client = {};
      if (couponType === "paid") {
        client = await getAdvertiser(clientID);
      } else {
        client = await getClient(clientID);
      }
      setClientData(client);
      setLoading(false);
    };
    loadClient();
  }, [clientID, couponType]);

  if (loading) {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row">
          <Col></Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }

  if (mode === "accept") {
    return (
      <Container fluid>
        <Header />
        <Row className="grid-container main-row">
          <Col>
            <CardAccept orderID={orderID} />
          </Col>
          <Col></Col>
          <Col></Col>
        </Row>
      </Container>
    );
  }

  //-- otherwise we are in mode WORK
  return (
    <Container fluid>
      <Header />
      <Row className="grid-container main-row">
        <Col>
          <CardWork
            orderID={orderID}
            clientData={clientData}
            updateClient={updateClient}
          />
        </Col>
        <Col>
          <CardAssets clientData={clientData} />
        </Col>
        <Col>
          <CardLibrary clientData={clientData} />
        </Col>
      </Row>
    </Container>
  );
}
