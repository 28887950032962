import React, { useEffect, useState, useMemo } from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const niceDate = (millis) => {
  const date = new Date(millis);
  return date.toLocaleDateString();
};

export default function CardCompleted(props) {
  const profile = useMemo(
    () => (props.designer ? props.designer : {}),
    [props.designer]
  );
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (profile) {
      const ordersData = profile.completedOrders;
      setOrders(ordersData);
      setLoading(false);
    }
  }, [profile]);

  if (loading) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Completed Last 90 Days</h3>
        </Row>
      </Col>
    );
  }

  if (!loading && orders.length === 0) {
    return (
      <Col className="dash-card">
        <Row className="title-row">
          <h3>Completed Last 90 Days</h3>
        </Row>
        <Row>
          <p>None completed yet.</p>
        </Row>
      </Col>
    );
  }

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Completed Last 90 Days</h3>
      </Row>
      <Row className="m-bottom-10">
        <p>
          Date&nbsp;&nbsp;:&nbsp;&nbsp;Client
          &nbsp;&nbsp;:&nbsp;&nbsp;Hours&nbsp;&nbsp;:&nbsp;&nbsp;Rating
        </p>
      </Row>
      {orders.map((order) => {
        return (
          <Row key={order.orderID}>
            <p>
              {niceDate(order.completed)} : {order.clientName} : {order.hours} :{" "}
              {order.rating}
            </p>
          </Row>
        );
      })}
    </Col>
  );
}
