import React, { useState, useEffect } from "react";
import "../css/printBOM.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import { getPendingRequests, setRequestsPrinted } from "../services/svcSignage";
import { Table } from "react-bootstrap";

export default function PrintBOM() {
  const [loading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);

  useEffect(() => {
    const loadRequests = async () => {
      const data = await getPendingRequests();
      setRequests(data);
      setLoading(false);
      const requestIDs = [];
      data.forEach((item) => {
        requestIDs.push(item.id);
      });
      await setRequestsPrinted(requestIDs);
    };
    loadRequests();
  }, []);

  if (loading) {
    return <Container fluid></Container>;
  }

  return (
    <Container className="print-bom-container" fluid>
      <Row>
        {requests.map((request, index) => {
          return (
            <Container className="print-bom-item" fluid key={index}>
              <Row style={{ flex: 1, justifyContent: "center", marginTop: 40 }}>
                <Image
                  className="print-bom-image"
                  src="https://storage.googleapis.com/snagm-b6068.appspot.com/public/snagm-logo.png"
                />
              </Row>
              <Row style={{ flex: 1, justifyContent: "center", marginTop: 40 }}>
                <h3 style={{ textAlign: "center" }}>
                  Your Requested Signage Is Enclosed
                </h3>
                <Row style={{ width: "50%", marginTop: 40 }}>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Material</th>
                        <th style={{ textAlign: "center" }}>Quantity</th>
                        <th style={{ textAlign: "center" }}>Packed</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Table Tents</td>
                        <td style={{ textAlign: "center" }}>
                          {request.data.tableTents}
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>Table Stickers</td>
                        <td style={{ textAlign: "center" }}>
                          {request.data.tableStickers}
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                      <tr>
                        <td>Drive Thru Stickers</td>
                        <td style={{ textAlign: "center" }}>
                          {request.data.driveThruStickers}
                        </td>
                        <td>&nbsp;</td>
                      </tr>
                    </tbody>
                  </Table>
                </Row>
                <Row style={{ marginTop: 40 }}>
                  <p style={{ textAlign: "center" }}>Mailing Address:</p>
                </Row>
                <Row>
                  <h4 style={{ textAlign: "center", marginTop: 20 }}>
                    {request.data.contactName}
                  </h4>
                </Row>
                <Row>
                  <h4 style={{ textAlign: "center" }}>
                    {request.data.clientName}
                  </h4>
                </Row>
                <Row>
                  <h4 style={{ textAlign: "center" }}>
                    {request.data.mailingAddr1}
                  </h4>
                </Row>
                {request.data.mailingAddr2 && (
                  <Row>
                    <h4 style={{ textAlign: "center" }}>
                      {request.data.mailingAddr2}
                    </h4>
                  </Row>
                )}
                <Row>
                  <h4 style={{ textAlign: "center" }}>
                    {request.data.mailingCity}
                    {", "}
                    {request.data.mailingState} {request.data.mailingZip}
                  </h4>
                </Row>
                <Row>
                  <h5 style={{ textAlign: "center", marginTop: 40 }}>
                    Any questions or concerns, please email:
                  </h5>
                </Row>
                <Row>
                  <h5 style={{ textAlign: "center" }}>support@snagm.com</h5>
                </Row>
                <Row style={{ marginTop: 60 }}>
                  <p style={{ textAlign: "center" }}>
                    Scan Code:&nbsp;{request.data.scancode}
                  </p>
                </Row>
              </Row>
              <p style={{ pageBreakAfter: "auto" }}>&nbsp;</p>
            </Container>
          );
        })}
      </Row>
    </Container>
  );
}
