import React, { useState, useEffect } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getPendingOrders } from "../services/svcOrders";

export default function CardInbox() {
  const navigate = useNavigate();
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const loadOrders = async () => {
      const orders = await getPendingOrders();
      const orderList = sortOrders(orders);
      setOrders(orderList);
    };
    loadOrders();
  }, []);

  const niceDate = (millis) => {
    const date = new Date(millis);
    return date.toLocaleTimeString([], {
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const sortOrders = (orderArray) => {
    //--sort array from oldest to newest
    const sorted = orderArray.sort((a, b) => {
      return a.submitted - b.submitted;
    });
    return sorted;
  };

  const goLink = (order) => {
    navigate({
      pathname: "work",
      search: createSearchParams({
        id: order.data.id,
        cid: order.clientID,
        t: order.data.couponType,
        m: "accept",
      }).toString(),
    });
  };

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Inbox</h3>
      </Row>
      {orders.length === 0 && (
        <Row>
          <p className="text-center">- - Empty - -</p>
        </Row>
      )}
      {orders.map((order, index) => (
        <Row key={index} className="v-top-5">
          <p className="link-style" onClick={() => goLink(order)}>
            {niceDate(order.submitted)} -- {order.data.clientName}
          </p>
        </Row>
      ))}
    </Col>
  );
}
