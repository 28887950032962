import { readDocument, updateDocument, writeDocument } from "./firestore";

export async function getClient(id) {
  const clientRecord = await readDocument("clients", id);
  const clientData = clientRecord.data;
  return clientData;
}

export async function getAdvertiser(id) {
  const clientRecord = await readDocument("advertisers", id);
  const clientData = clientRecord.data;
  return clientData;
}

export async function writeClient(data) {
  const clientRecord = {
    zip: data.clientZIP,
    data: data,
    deployment: data.cityState,
  };
  const clientData = await writeDocument(
    "clients",
    data.clientID,
    clientRecord
  );
  return clientData;
}

export async function writeAdvertiser(data) {
  const clientRecord = { data };
  const clientData = await writeDocument(
    "advertisers",
    data.clientID,
    clientRecord
  );
  return clientData;
}

export async function updateClient(id, data) {
  const clientRecord = {
    zip: data.clientZIP,
    data: data,
    deployment: data.cityState,
  };
  const clientData = await updateDocument("clients", id, clientRecord);
  return clientData;
}
