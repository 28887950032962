import {
  writeDocument,
  getCollection,
  readDocument,
  queryCollection,
} from "./firestore";
import { isoToday, genID } from "./svcFunctions";

export const trackPhoto = async (filename) => {
  const docKey = filename.slice(0, -4);
  const newDoc = {
    uploaded: isoToday(),
  };
  await writeDocument("uploads", docKey, newDoc);
  return;
};

export const saveOrder = async (order) => {
  const docKey = genID(12);
  await writeDocument("orders", docKey, order);
  return;
};

export const getPendingOrders = async () => {
  const orders = await getCollection("orders-pending");
  if (orders) {
    return orders;
  }
  return [];
};

export const getPendingOrder = async (orderID) => {
  const order = await readDocument("orders-pending", orderID);
  if (order) {
    return order;
  }
  return null;
};

export const getWorkingOrders = async (id) => {
  const orders = await queryCollection("orders-working", "uid", id);
  if (orders) {
    return orders;
  }
  return [];
};

export const getWorkingOrder = async (orderID) => {
  const order = await readDocument("orders-working", orderID);
  if (order) {
    return order;
  }
  return null;
};
