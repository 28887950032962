import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "../css/printTable.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { getClient } from "../services/svcClient";
import { makeLink } from "../services/svcFunctions";

export default function PrintTable() {
  const [searchParams] = useSearchParams();
  const scanCode = "SNAGM DIGITAL COUPONS";
  //const scanCode = searchParams.get("sc");
  const clientID = searchParams.get("id");
  const imageFile = searchParams.get("img");
  const [loading, setLoading] = useState(true);
  const [clientName, setClientName] = useState("");
  const [imageURL, setImageURL] = useState("");

  const topTitle2 = "Exclusive Coupons";

  useEffect(() => {
    const loadClient = async () => {
      if (!clientID) return;
      const client = await getClient(clientID);
      setClientName(client.clientName);
      const fileName = imageFile + ".png";
      const link = makeLink("scancodes", fileName);
      setImageURL(link);
      setLoading(false);
    };
    loadClient();
  }, [clientID, imageFile]);

  if (loading) {
    return <Container fluid></Container>;
  }

  return (
    <Container className="print-table-container" fluid>
      <Row className="print-table-row">
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
      </Row>
      <Row className="print-table-row">
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
      </Row>
      <Row className="print-table-row">
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
      </Row>
      <Row className="print-table-row">
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
      </Row>
      <Row className="print-table-row">
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
        <Col className="print-table-block">
          <p className="print-table-client">{clientName}</p>
          <p className="print-table-top">{topTitle2}</p>
          <Image className="print-table-image" src={imageURL} />
          <p className="print-table-logo">{scanCode}</p>
        </Col>
      </Row>
    </Container>
  );
}
