import { getApp } from "firebase/app";
import { ref, getDownloadURL, getStorage, uploadBytes } from "firebase/storage";
import { genID } from "../services/svcFunctions";

export const getImageURL = async (filename) => {
  const folderPath = "images/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  try {
    const imageURL = await getDownloadURL(storageRef);
    return imageURL;
  } catch (error) {
    console.log("Error getting image URL: ", error);
    return "";
  }
};

export const getPhotoURL = async (filename) => {
  const folderPath = "photos/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  try {
    const imageURL = await getDownloadURL(storageRef);
    return imageURL;
  } catch (error) {
    console.log("Error getting image URL: ", error);
    return "";
  }
};

export const uploadImage = async (fileObj) => {
  const filename = genID(12) + ".jpg";
  const folderPath = "images/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  await uploadBytes(storageRef, fileObj);
  return filename;
};

export const uploadPhotos = async (fileObj) => {
  const filename = genID(12) + ".jpg";
  const folderPath = "photos/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  await uploadBytes(storageRef, fileObj);
  return filename;
};

export const uploadPhoto = async (fileObj) => {
  const filename = genID(12) + ".jpg";
  const folderPath = "photos/" + filename;
  const storage = getStorage(getApp());
  const storageRef = ref(storage, folderPath);
  await uploadBytes(storageRef, fileObj);
  return filename;
};
