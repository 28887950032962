import { readDocument } from "../services/firestore";

export async function getProfile(uid) {
  try {
    const profile = await readDocument("users", uid);
    return profile;
  } catch (error) {
    return null;
  }
}

export async function getDesigner(id) {
  try {
    const profile = await readDocument("designers", id);
    return profile.data;
  } catch (error) {
    return null;
  }
}
