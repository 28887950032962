import React from "react";
import "../css/cards.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { makeLink } from "../services/svcFunctions";

export default function CardLibrary(props) {
  const { clientData } = props;
  let libraryList = [];
  if ("library" in clientData) {
    libraryList = clientData.library;
  }

  //TODO: if no assets, say something

  return (
    <Col className="dash-card">
      <Row className="title-row">
        <h3>Client Library</h3>
      </Row>
      {libraryList.map((filename, index) => (
        <Row key={index} className="v-top-5">
          <Image src={makeLink("images", filename)} />
        </Row>
      ))}
    </Col>
  );
}
